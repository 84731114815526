<template>
  <component :is="'GCheckbox'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-checkbox">
    <template v-slot:checkbox="{ checked }">
      <div class="checkbox" :class="{ checked }">
        <IconCheckmark v-if="checked" class="icon-checkmark" />
      </div>
    </template>
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import GCheckbox from '@/components/GCheckbox.vue';
import IconCheckmark from '@/assets/icons/checkmark.svg';

export default {
  name: 'PassportCheckbox',
  inheritAttrs: false,
  extends: GCheckbox,
  components: {
    GCheckbox,
    IconCheckmark,
  },
};
</script>

<style lang="scss" scoped>
.passport-checkbox {
  color: white;
  ::v-deep {
    .checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border: 1px solid currentColor;
      border-radius: 3px;

      &.checked {
        background-color: #fff;
      }
    }
    input[type='checkbox'] {
      width: 16px;
      height: 16px;
    }
    .icon-checkmark {
      width: 10px;
      path {
        fill: black;
        stroke: black;
      }
    }
  }
  //
}
</style>
